import React from "react";
import Layout from '../components/layout';
import * as indexCss from './modules/index.module.scss';
import {graphql, useStaticQuery, Link} from "gatsby";
import {documentToReactComponents} from "@contentful/rich-text-react-renderer";

const IndexPage = () => {
    const datas = useStaticQuery(graphql`
    query {
        allContentfulAccueil {
            edges {
                node {
                    titre
                    sousTitre
                    texteDintroduction {
                        raw
                    }
                    titreProchainement
                    texteProchainement
                    lienProchainement
                    quelquesArtistes
                    imageCollection {
                        file {
                            url
                        }
                        title
                    }
                }
            }
        }
    }`);
    const data = datas.allContentfulAccueil.edges[0].node;
    const content = JSON.parse(data.texteDintroduction.raw);

    return (
        <Layout>
            <section className={indexCss.banner}>
                <div className={indexCss.container}>
                    <div className={indexCss.content}>
                        <h1>{data.titre}</h1>
                        <h2>{data.sousTitre}</h2>
                        <div>{documentToReactComponents(content)}</div>
                    </div>

                    <div className={indexCss.hero}></div>
                </div>
            </section>
            <Link to={data.lienProchainement}>
                <section className={indexCss.tocome}>
                    <p className={indexCss.tocomeTitle}> {data.titreProchainement} </p>
                    <p className={indexCss.tocomeText}> {data.texteProchainement} </p>
                </section>
            </Link>

            <section className={indexCss.collectionContainer}>
                <h3>{data.quelquesArtistes}</h3>
                <div className={indexCss.collection}>
                    {data.imageCollection.map(image => {
                        return (
                            <div className={indexCss.imageContainer}>
                                <a href={image.file.url} target="_blank" rel="noreferrer">
                                    <img src={image.file.url} alt={image.title} className={indexCss.image}/>
                                </a>
                                <p className={indexCss.caption}>{image.title}</p>
                            </div>
                        )
                    })}
                </div>
            </section>
        </Layout>
    )
};

export default IndexPage;
