// extracted by mini-css-extract-plugin
export var banner = "index-module--banner--ltZXs";
export var container = "index-module--container--28nld";
export var hero = "index-module--hero--3XdaB";
export var tocome = "index-module--tocome--1Jc-0";
export var tocomeTitle = "index-module--tocomeTitle--1cjZG";
export var tocomeText = "index-module--tocomeText--HZxeu";
export var collectionContainer = "index-module--collectionContainer--10gSh";
export var collection = "index-module--collection--2w36m";
export var imageContainer = "index-module--imageContainer--2TYkC";
export var image = "index-module--image--IXSon";
export var caption = "index-module--caption--2U8M3";
export var content = "index-module--content--32Lu5";